import React from "react";
import "./style.css";

export const Header = () => {
  return (
    <div className="header-section">
      <div className="frame">
        <div className="header-text-content">
          <p className="text-wrapper">Increase Your Customers Loyalty and Satisfaction</p>
          <p className="we-help-businesses">
            We help businesses like yours earn more customers, standout from competitors,&nbsp;&nbsp;make more money
          </p>
        </div>
        <button className="header-CTA">
          <div className="div">Get Started</div>
        </button>
      </div>
      <div className="our-partners">
        <div className="group">
          <img className="logos-monday" alt="Logos monday" src="https://c.animaapp.com/D9fpVXAk/img/logos-monday.svg" />
          <img className="logos-google" alt="Logos google" src="https://c.animaapp.com/D9fpVXAk/img/logos-google.svg" />
          <img
            className="trello-logo-blue"
            alt="Trello logo blue"
            src="https://c.animaapp.com/D9fpVXAk/img/trello-logo-blue-1@2x.png"
          />
          <img className="notion" alt="Notion" src="https://c.animaapp.com/D9fpVXAk/img/notion@2x.png" />
          <img className="slack" alt="Slack" src="https://c.animaapp.com/D9fpVXAk/img/slack@2x.png" />
        </div>
      </div>
    </div>
  );
};
