import React from 'react'
import '../App.css';
import { Navbar } from "../screens/Navbar";
import Contacts from '../Contact';
import {Footer} from '../screens/Footer';
const Contact = () => {
  return (
    <>
    <Navbar/>
    <Contacts></Contacts>
    <Footer></Footer>
    </> 
    )
}

export default Contact