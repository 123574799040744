import React from 'react';
import "./attribute-card.css";

export const AttributeCard = ({ icon, title, value }) => {
  return (
    <div className="attribute-card">
        <div className='inner-container'>
            <div className="attribute-icon">
            <img src={icon} alt={'No Image Found'} /> 
            </div>
            <div className="attribute-title">
                <p>{title}</p>
            </div>
            <div className='attribute-value'><p>{value}</p></div>
        </div>
    </div>
  );
};
