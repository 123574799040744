import * as React from 'react';
import { useState } from 'react';

const Contact = () => {
    const [userData , setUserData] = useState({
        firstName:"",
        lastName:"",
        phoneNo:"",
        email:"",
        address:"",
        message:""
    })
    let name,value;
	//updating the state variable values
    const postUserData = (event) => {
        name=event.target.name;
        value=event.target.value;
        setUserData({...userData,[name]:value});
    }
    //connect with firebase
    const submitData = async (event)=>{
        event.preventDefault();
        const {firstName, lastName, phoneNo, email, address, message} = userData;
		if(firstName && lastName && phoneNo && email && address && message)//checking if all fields are filled
		{
			//hitting the api to store the data
			const res =  fetch("https://webstrix-2fb45-default-rtdb.firebaseio.com/userDataRecord.json",
			{
				method:"POST",
				headers: {
				"Content-Type":"application/json"
				},
				body:JSON.stringify({
				firstName, lastName, phoneNo, email, address, message
				}),
			});

			if(res){//clearing the fields and giving the alert message
				setUserData({
					firstName:"",
					lastName:"",
					phoneNo:"",
					email:"",
					address:"",
					message:""
				});
				alert("data-stored !");
			}
			else
				alert("data storing failed !");	
		}
		else
			alert("please enter all the required fields !");
       	
    }
  return (
    <>
    <section className='contactus-section'>
        <div className='container'>
            <div className='row'>
                <div className='col-12 col-lg-10 mx-auto'>
                    <div className='row'>
                        <div className='contact-leftside col-12 col-lg-5'>
                            <h1 className='main-heading fw-bold'>Contact with our <br/>sales team.</h1>
                            <p className='main-hero-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
                            <figure>
                                <img src="./images/contactus.jpg" alt="ContactUsImg" className='img-fluid'></img>
                            </figure>
                        </div>
                        {/**right side contact form */}
                        <div className='contact-rightside col-12 col-lg-7'>
                            <form method='POST'>
                                <div className='row'>
                                    <div className='col-12 col-lg-6 contact-input-field'>
                                        <input type="text" name="firstName" id="" className='form-control' placeholder='First Name' value={userData.firstName} onChange={postUserData}></input>
                                    </div>
                                    <div className='col-12 col-lg-6 contact-input-field'>
                                        <input type="text" name="lastName" id="" className='form-control' placeholder='Last Name' value={userData.lastName} onChange={postUserData}></input>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-lg-6 contact-input-field'>
                                        <input type="text" name="phoneNo" id="" className='form-control' placeholder='Phone No' value={userData.phoneNo} onChange={postUserData}></input>
                                    </div>
                                    <div className='col-12 col-lg-6 contact-input-field'>
                                        <input type="text" name="email" id="" className='form-control' placeholder='Email' value={userData.email} onChange={postUserData}></input>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-lg-12 contact-input-field'>
                                        <input type="text" name="address" id="" className='form-control' placeholder='Add address' value={userData.address} onChange={postUserData}></input>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 col-lg-12 contact-input-field'>
                                        <input type="text" name="message" id="" className='form-control' placeholder='Enter your message' value={userData.message} onChange={postUserData}></input>
                                    </div>
                                </div>
                                <div className='form-check '>
                                    <input className='form-check-input form-checkbox-style ' type='checkbox' value=''></input>
                                    <label className='form-check-label main-hero-para'> I agree to all term and conditions.</label>
                                </div>
                                <button className='btn btn-style w-100' type='submit' onClick={submitData}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Contact