import React from "react";
import "./style.css";

export const OurPortfolio = () => {
    return (
        <div className="our-portfolio">
            <div className="text-wrapper">OUR PORTFOLIO</div>
            <p className="div">We provide the Perfect Solution to your business growth</p>
            <div className="frame">
                <img className="screenshot" alt="Screenshot" src="images/OurPortfolio/screenshot1.png" />
                <div className="frame-2">
                    <div className="text-wrapper-2">Digital Marketing Agency Website</div>
                    <p className="p">
                        This is a website for a client who want to achieve their goals and meet their users needs while also
                        increasing their reach. Accross all platforms. This is a website rebrand
                    </p>
                </div>
            </div>
            <div className="frame-3">
                <img className="img" alt="Screenshot" src="images/OurPortfolio/screenshot2.png" />
                <div className="frame-4">
                    <div className="text-wrapper-3">Digital Marketing Agency Website</div>
                    <p className="text-wrapper-4">
                        This is a website for a client who want to achieve their goals and meet their users needs while also
                        increasing their reach. Accross all platforms. This is a website rebrand
                    </p>
                </div>
            </div>
            <div className="frame-5">
                <img className="screenshot-2" alt="Screenshot" src="images/OurPortfolio/screenshot3.png" />
                <div className="frame-2">
                    <div className="text-wrapper-2">Digital Marketing Agency Website</div>
                    <p className="p">
                        This is a website for a client who want to achieve their goals and meet their users needs while also
                        increasing their reach. Accross all platforms. This is a website rebrand
                    </p>
                </div>
            </div>
            <div className="frame-6">
                <img className="screenshot-3" alt="Screenshot" src="images/OurPortfolio/screenshot4.png" />
                <div className="frame-7">
                    <div className="text-wrapper-5">Digital Marketing Agency Website</div>
                    <p className="text-wrapper-6">
                        This is a website for a client who want to achieve their goals and meet their users needs while also
                        increasing their reach. Accross all platforms. This is a website rebrand
                    </p>
                </div>
            </div>
        </div>
    );
};
