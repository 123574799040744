import React from "react";
import "./style.css";

export const Footer = () => {
  return (
    <div className="footer">
      <div className="overlap-group">
        <div className="frame">
          <div className="text-wrapper">WEB LOGO</div>
        </div>
        <p className="some-footer-text">
          Some footer text about the&nbsp;&nbsp;Agency. Just a little description to help people understand you better
        </p>
      </div>
      <div className="frame-wrapper">
        <div className="div">
          <div className="group">
            <img className="vector" alt="Vector" src="https://c.animaapp.com/v1ZhT2pt/img/vector.svg" />
          </div>
          <div className="group">
            <img className="img" alt="Vector" src="https://c.animaapp.com/v1ZhT2pt/img/vector-1.svg" />
          </div>
          <div className="group">
            <img className="vector-2" alt="Vector" src="https://c.animaapp.com/v1ZhT2pt/img/vector-2.svg" />
          </div>
          <img className="frame-2" alt="Frame" src="https://c.animaapp.com/v1ZhT2pt/img/frame-20095.svg" />
        </div>
      </div>
      <div className="text-wrapper-2">Quick Links</div>
      <div className="text-wrapper-3">Address</div>
      <div className="frame-3">
        <div className="text-wrapper-4">Services</div>
        <div className="text-wrapper-5">Portfolio</div>
        <div className="text-wrapper-5">About Us</div>
        <div className="text-wrapper-5">Contact Us</div>
      </div>
      <p className="design-agency-head">
        Design Agency Head Office.
        <br />
        Airport Road
        <br />
        United Arab Emirate
      </p>
      <div className="text-wrapper-6">Copyright Design Agency 2022</div>
    </div>
  );
};
