/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Activity } from "../Activity";
import "./style.css";

export const WhatWeDoCard = ({
  headerText = "Grow Your Business",
  subtext = "We help identify the best ways to improve your business",
  property1,
  vectorClassName,
  override = <Activity className="activity-instance" />,
  vector = "https://c.animaapp.com/MOzEWyRk/img/vector-3.svg",
}) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  return (
    <div
      className={`what-we-do-card ${state.property1}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      <div className="frame">
        {override}
        <div className="frame">
          <div className="grow-your-business">{headerText}</div>
          <p className="we-help-identify-the">{subtext}</p>
        </div>
      </div>
      <button className="learn-more-btn">
        <div className="text-wrapper">Learn More</div>
        <img
          className={`vector ${vectorClassName}`}
          alt="Vector"
          src={state.property1 === "hover" ? "https://c.animaapp.com/MOzEWyRk/img/vector-4.svg" : vector}
        />
      </button>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

WhatWeDoCard.propTypes = {
  headerText: PropTypes.string,
  subtext: PropTypes.string,
  property1: PropTypes.oneOf(["hover", "default"]),
  vector: PropTypes.string,
};
