import React from 'react';
import './App.css';
import Home from "./Home";
import { Switch ,Route } from 'react-router-dom';
import AboutUs from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
const App=()=> {
  return (
   <>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path="/about" component={AboutUs} ></Route>
      <Route exact path="/contact" component={Contact} ></Route>
      <Route exact path="/services" component={Services} ></Route>
    </Switch>
   </>
  );
}

export default App;
