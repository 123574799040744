import React from 'react'
import { Navbar } from "./screens/Navbar";
import {Header} from "./screens/Header";
import HowDoesItWorks from "./HowItWorks.js";
import AboutUs from "./AboutUs";
import Services from './Services.js';
import Contact from './Contact';
import { WhatWeDo } from "./screens/WhatWeDo";
import { WhatOurCustomerSays } from "./screens/WhatOurCustomerSays";
import { Footer } from "./screens/Footer"
import { Frame14 } from './screens/Frame14';
import { OurPortfolio } from './screens/OurPortfolio/OurPortfolio.js';
const Home=()=> {
  return (
   <>
    <Navbar/>
    <Header/>
    <WhatWeDo/>
    <Frame14/>
    <OurPortfolio/>
    {/* <WhatOurCustomerSays/> */}
    <Contact/>
    <Footer></Footer>
   </>
  );
}

export default Home;
