import React from 'react'
import '../App.css';
import { Navbar } from "../screens/Navbar";
import AboutUS from '../AboutUs';
import {Footer} from '../screens/Footer';
const About = () => {
  return (
    <>
    <Navbar/>
    <AboutUS></AboutUS>
    <Footer></Footer>
    </>
  )
}

export default About