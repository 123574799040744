import React,{useState} from 'react'
import HowItWork from './api/howItWork';
function AboutUs() {
    const [aboutData,setAboutData] = useState(HowItWork);
  return (
    <>
        <section className='common-section our-services'>
            <div className='container mb-5'>
                <div className='row'>
                <div className='col-12 col-lg-5 text-center our-service-leftside-img'>
                    <img src='./images/demo5.jpg' alt='aboutUsImg'></img>
                </div>
                {/* 1st section  right side data */}
                <div className='col-12 col-lg-7 our-services-list'>
                    <h3 className='mini-title'> --Available @ Google Store and IOS App Stor Only</h3>
                    <h1 className='main-heading'>How to use the App?</h1>
                        <div className='row our-services-info'>
                            {
                                aboutData.map((curElement)=>
                                {
                                    const {id,title,info} = curElement;
                                    return(
                                    <>
                                        <div className='col-1 our-services-number'>{id}</div>
                                        <div className='col-11 our-services-data'>
                                            <h2>{title}</h2>
                                            <p className='main-hero-para'>{info}</p>
                                        </div>                                
                                    </>);
                                })
                            }  
                            <div>
                            <button className='btn-style btn-style-border'>Learn More</button>

                            </div>
                        </div>  
                    </div>   
                </div>
            </div>
        </section>
        {/** second section */}
        <section className='common-section our-services'>
            <div className='container mb-5'>
                <div className='row'>
               
                {/* 1st section  right side data */}
                <div className='col-12 col-lg-7 our-services-list'>
                    <h3 className='mini-title'> --Support in any language</h3>
                    <h1 className='main-heading'>World class support is available 24/7</h1>
                        <div className='row our-services-info'>
                            {
                                aboutData.map((curElement)=>
                                {
                                    const {id,title,info} = curElement;
                                    return(
                                    <>
                                        <div className='col-1 our-services-number'>{id}</div>
                                        <div className='col-11 our-services-data'>
                                            <h2>{title}</h2>
                                            <p className='main-hero-para'>{info}</p>
                                        </div>                                
                                    </>);
                                })
                            }  
                            <div>
                            <button className='btn-style btn-style-border'>Learn More</button>

                            </div>
                        </div>  
                    </div>   
                    <div className='col-12 col-lg-5 text-center our-service-leftside-img'>
                    <img src='./images/demo5.jpg' alt='aboutUsImg'></img>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutUs