import React from 'react';
import { AttributeCard } from '../../components/AttributeCard';
import '../../components/AttributeCard/attribute-card.css';

export const Frame14 = () => {
  return (
    <div className="attribute-card-container">
    <AttributeCard icon='images/AttributeIcons/timeline.png' title="Years in Business" value={'5 yrs'} />
    <AttributeCard icon="images/AttributeIcons/project-plan.png" title="Completed Projects" value={'100 +'} />
    <AttributeCard icon="images/AttributeIcons/rating.png" title="Customer Satisfaction" value="95 %" />
    <AttributeCard icon="images/AttributeIcons/raise.png" title="Raised by Clients" value={'$10M'} />
  </div>
  );
};

