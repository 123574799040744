import React from "react";
import { Heart } from "../../components/Heart";
import { WhatWeDoCard } from "../../components/WhatWeDoCard";
import { Work } from "../../components/Work";
import "./style.css";

export const WhatWeDo = () => {
  return (
    <div className="what-we-do">
      <div className="div">
        <div className="frame-2">
          <div className="text-wrapper-2">WHAT WE DO</div>
          <p className="p">We provide the Perfect Solution to your business growth</p>
        </div>
        <div className="frame-3">
          <WhatWeDoCard
            headerText="Grow Your Business"
            property1="default"
            subtext="We help identify the best ways to improve your business"
            vectorClassName="what-we-do-card-instance"
          />
          <WhatWeDoCard
            headerText="Improve brand loyalty"
            override={<Heart className="design-component-instance-node" />}
            property1="default"
            subtext="We help identify the best ways to improve your business"
            vector="https://c.animaapp.com/MOzEWyRk/img/vector-2.svg"
            vectorClassName="what-we-do-card-instance"
          />
          <WhatWeDoCard
            headerText="Improve Business Model"
            override={<Work className="design-component-instance-node" />}
            property1="default"
            subtext="We help identify the best ways to improve your business"
            vector="https://c.animaapp.com/MOzEWyRk/img/vector-1.svg"
            vectorClassName="what-we-do-card-instance"
          />
        </div>
      </div>
    </div>
  );
};
