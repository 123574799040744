import React from 'react'
import '../App.css';
import { Navbar } from "../screens/Navbar";
import Service from '../Services';
import {Footer} from '../screens/Footer';
const Services = () => {
  return (
    <>
    <Navbar/>
    <Service></Service>
    <Footer></Footer>
    </>  
  )
}

export default Services