import React from "react";
import { Frame } from "../../components/Frame";
import "./style.css";

export const Navbar = () => {
  return (
    <div className="main-menu-content">
      <div className="design-AGENCY-wrapper">
        <p className="design-AGENCY">
            <a href='/'>
                <span className="text-wrapper">WebStrix </span>
                <span className="span"> we grow together</span>
            </a>          
        </p>
      </div>
      <div className="div">
        <Frame className="frame-1" divClassName="frame-instance" href="/Services"  mainMenuText="Services" />
        <Frame className="frame-1" divClassName="frame-instance" href="/About" mainMenuText="About Us" />
        <Frame className="frame-1" divClassName="frame-instance" href="/contact" mainMenuText="Contact Us" />
        <Frame className="frame-1-instance" divClassName="frame-instance" mainMenuText="Login" />
        <Frame className="design-component-instance-node" divClassName="frame-2" mainMenuText="Register" />
      </div>
    </div>
  );
};
