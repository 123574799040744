import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Frame = ({ dropIcon = false, mainMenuText = "Menu Items", className, divClassName,href }) => {
  return (
    <div className={`frame ${className}`}>
      <div className={`menu-items ${divClassName}`}><a href={href}>{mainMenuText}</a></div>
    </div>
  );
};

Frame.propTypes = {
  dropIcon: PropTypes.bool,
  mainMenuText: PropTypes.string,
};
